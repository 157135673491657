import React, { Component } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      phoneNumbers: [],
      displayedNumbers: 0,
      allNumbers: 0
    };
  }

  formatInput = (value) => {
    const lines = value.split(/\n|,/);

    const result = lines.map((line) => {
      const digitsOnly = line.replace(/[^\d]/g, "");
      const formattedLine = digitsOnly.replace(/^8/, "7");
      if (formattedLine.length === 10) {
        return "7" + formattedLine;
      } else if (formattedLine.length === 11) {
        return formattedLine;
      }
    });

    const uniqueResult = [...new Set(result.filter(Boolean))];
    return uniqueResult;
  };

  handleInputChange = (e) => {
    const value = e.target.value;
    const formattedNumbers = this.formatInput(value);
    this.setState({
      inputValue: value,
      phoneNumbers: formattedNumbers,
      displayedNumbers: formattedNumbers.length, // update displayed numbers count
      allNumbers: formattedNumbers.length, // update allNumbers count
    });
  };
  
  handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const fileContents = reader.result;
      const formattedNumbers = this.formatInput(fileContents);
      this.setState({
        inputValue: fileContents,
        phoneNumbers: formattedNumbers,
        displayedNumbers: formattedNumbers.length, // update displayed numbers count
        allNumbers: formattedNumbers.length, // update allNumbers count
      });
    };
    reader.readAsText(file);
  };

  handleSaveButtonClick = () => {
    const { displayedNumbers, phoneNumbers, allNumbers } = this.state;
    const normalizedNumbers = phoneNumbers.map((num) => num.replace(/[^0-9]/g, ""));
    const totalNumbers = Math.min(displayedNumbers, normalizedNumbers.length); // по сколько номеров в каждом файле
  
    const batchSize = totalNumbers; // по сколько номеров в каждом файле
    const numberOfBatches = Math.ceil(allNumbers / batchSize); // кол-во файлов
  
    console.log(batchSize);
    console.log(allNumbers);
    console.log(numberOfBatches);
  
    const zip = new JSZip();
  
    for (let i = 0; i < numberOfBatches; i++) {
      const startIndex = i * batchSize;
      const endIndex = Math.min(startIndex + batchSize, allNumbers);
      const batchNumbers = normalizedNumbers.slice(startIndex, endIndex);
  
      let fileContent = '';
      for (const number of batchNumbers) {
        fileContent += number + ',\n';
      }
  
      zip.file(`phone_numbers_${i + 1}.txt`, fileContent);
    }
  
    zip.generateAsync({ type: "blob" })
      .then((content) => {
        saveAs(content, "phone_numbers.zip");
      });
  };

  handleDisplayedNumbersChange = (e) => {
    const displayedNumbers = e.target.value;
    this.setState({
      displayedNumbers,
    });
  };

  render() {
    const { inputValue, phoneNumbers, displayedNumbers } = this.state;
    const displayedPhoneNumbers = phoneNumbers.slice(0, displayedNumbers); // get subset of phone numbers to display
    return (
      <>
        <input type="file" onChange={this.handleFileInputChange} />
        <textarea
          onChange={this.handleInputChange}
          value={inputValue}
          style={{ display: "none" }}
        ></textarea>
        <button onClick={this.handleSaveButtonClick}>Сохранить</button>
        По сколько номеров выгрузить: <input
          type="number"
          value={displayedNumbers}
          onChange={this.handleDisplayedNumbersChange}
        />
        {phoneNumbers.length > 0 && (
          <div style={{ paddingLeft: 50 }}>
            <p>Отформатированные номера телефонов:</p>
            {displayedPhoneNumbers.map((phoneNumber, index) => (
            <span key={index}>
                {index === 0 ? phoneNumber : `,${phoneNumber}`}
                <br />
            </span>
            ))}
        </div>
        )}
      </>
    );
  }
}

export default App;